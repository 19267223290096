<template>
	<main v-if="horse && horse.horse_id && !loading">
		<HeaderTab 
            :title="horse.horse_nom | multiCapitalize"
            :horse="horse"
            :presence="presence"
            :swiper_tabs="swiper_tabs"
            horse_readonly 
        />
		<div id="content">
			<div class="container-fluid">
				<div class="row">
					<div class="col-12">
						<div>
							<keep-alive>
								<router-view :horse="horse"></router-view>
							</keep-alive>
						</div>
					</div>
				</div>
			</div>
		</div>
	</main>

	<main v-else>
		<div id="content">
			<div class="container-fluid">
				<div class="row">
					<div class="col-12">
						<div class="box" v-if="loading">
							{{ $t('global.chargement') }}
						</div>
						<div class="box" v-else>
							<HorseNotFound />
						</div>
					</div>
				</div>
			</div>
		</div>
	</main>
</template>


<script type="text/javascript">
	import HorseMixin from "@/mixins/Horse.js"
	import ActeMixin from "@/mixins/Actes.js"

	export default {
		name: "HorseFiche",
		mixins: [
            ActeMixin,
            HorseMixin
        ],
		data () {
			return {
                loading: true,
				horse_id: 0,
				horse: {},
				presence: null,
                has_acts: false,
                has_products: false
			}
		},
		created() {
            this.$store.commit({ type: 'updatehorseFicheReadonly', val: true })
		},
		mounted() {
			this.horse_id = this.$route.params.horse_id
			this.init_component()
		},
		methods: {
			async init_component() {

					Promise.all([
						this.loadHorse()
					]).then(() => {
						if(this.$route.name == 'horseFiche'){
							this.$router.replace({ name: this.actual_tab })
						}

						if(this.swiper_tabs.filter(elem => elem.href == 'HorseFicheInfo').length == 0 && this.$route.name == 'HorseFicheInfo'){
							this.$router.replace({ name: this.swiper_tabs[0].href })
						}

					}).finally(() => {
						this.loading = false
					})
					
					this.getHorseActualPlace(this.horse_id)
					.then(result => {
						this.presence = result
					})
					.catch(e => {
						this.presence = null
					})
			},
			async loadHorse() {
                    const horse_id = parseInt(this.horse_id)

                    // On charge les infos du cheval (en cache cond)
                    this.horse = await this.getHorseById(horse_id).catch(e => {
                        console.error(e)
                        return null
                    })

                    this.horse.use_dedicated_recap = true // pour monceaux
                    this.has_acts = this.horse.has_acts
			},
            async checkHasProducts() {
                if(!this.isMare) {
                    this.has_products = false
                    return
                }

                const temp = await this.getHorseChildren(this.horse_id).catch(e => {
                    return null
                })
                this.has_products = (temp && temp.length > 0)
            }
		},
		computed: {
            all_tabs() {
                return [
					{
						label: 'global.informations',
						href: 'HorseFicheInfo',
						active: true
					},
                    {
						label: 'global.informations',
						href: 'HorsePedigreePdf',
						active: true
					},
					{
						label: 'global.actes',
						href : 'HorseFicheActes',
						hidden: !this.hasActesAccess || !this.has_acts,
						active: false
					},
                    {
						label: 'global.notes',
						href : 'HorseFicheNotes',
						active: false
					},
                    {
						label: 'horse.feed',
						href : 'HorseFeed',
						hidden: !this.hasSocialFeedAccess,
						active: false
					},
					{
						label: 'monte.contract',
						href : 'ContractMareList',
						hidden: !this.hasSaillieMareAccess,
						active: false,
					},
                    {
						label: 'horse.infos_monte',
						href : 'HorseMonteInfo',
						hidden: !this.hasMonteInfosAccess || !this.isMare || this.isFoalYearling || this.isOutOfStructure || !this.has_products,
						active: false
					},
                    {
						label: 'horse.production',
						href : 'HorseProduct',
                        hidden: !this.isMare || !this.has_products,
						active: false
					},
					{
						label: 'horse.production',
						href : 'HorseProductDedicated',
                        hidden: !this.isMare || this.isFoalYearling || this.isOutOfStructure,
						active: false
					},
                    {
						label: 'global.perfs',
						href : 'HorsePerfs',
						hidden: !this.hasPerfsAccess || this.isFoalYearling,
						active: false
					},
                    {
						label: 'horse.suivi_croissance',
						href : 'HorseSuiviCroissance',
						hidden: !this.hasSuiviCroissanceAccess,
						active: false
					},
                    {
						label: 'horse.caracterisation_pax',
						href : 'HorsePax',
						hidden: !this.hasPaxAccess,
						active: false
					}
				]
            },
			swiper_tabs() {
                const horseFicheTabs = this.$store.state.common.tabs.horseFiche

                // On retourne les onglets dans l'ordre de la config
                let tabs = horseFicheTabs.map(tab => {
                    return this.all_tabs.find(t => t.href == tab)
                })

				if(tabs.filter(elem => elem.active == true).length == 0){
					tabs[0].active = true
				}
				
				return tabs
			},
            actual_tab() {
                const tab = this.swiper_tabs.find(st => st.active === true)
                return tab.href
            },
            isMare() {
				return this.horse.horse_sexe == "F"
			},
			hasSaillieMareAccess() {
				return (this.horse.horse_sexe == "F") && this.$store.state.userAccess.hasSaillieAccess
			},
            hasActesAccess() {
                return this.$store.state.userAccess.hasActesAccess
            },
            hasSocialFeedAccess() {
                return this.$store.state.userAccess.hasSocialFeedAccess
            },
            hasMonteInfosAccess() {
                return this.$store.state.userAccess.hasMonteInfosAccess
            },
            hasPerfsAccess() {
                return this.$store.state.userAccess.hasPerfsStatsAccess
            },
            hasSuiviCroissanceAccess() {
            	return this.$store.state.userAccess.hasSuiviCroissanceAccess
            },
            hasPaxAccess() {
            	return this.$store.state.userAccess.hasPaxAccess
            },
            routeId() {
                return this.$route.params.horse_id
            },
            isFoalYearling() {
                if(this.horse.horse_categories.length === 0) return false

                const ids_monceaux = [2, 3] // oui
                const temp = this.horse.horse_categories.find(c => ids_monceaux.indexOf(c.categorie_id) > -1)
                return !!temp
            },
            isOutOfStructure() {
                if(this.horse.horse_categories.length === 0) return false

                const ids_monceaux = [12]
                const temp = this.horse.horse_categories.find(c => ids_monceaux.indexOf(c.categorie_id) > -1)
                return !!temp
            }
		},
        watch: {
            routeId(val) {
                this.loading = true
                this.horse_id = val
                this.init_component()
            },
            isMare() {
                this.checkHasProducts()
            }
        },
		components: {
			HorseNotFound: () => import('GroomyRoot/components/Horse/HorseNotFound'),
			HeaderTab: () => import('GroomyRoot/components/HeaderTab'),
		}
	}
</script>
